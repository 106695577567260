.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 980px;
  margin: 10vh auto 0;
  padding: 20px 0;
}

.link {
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 30px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s ease;
  background-color: #657e91;
  box-shadow: 0 6px 0 #374957;
  font-size: 1.5em;
}