.header {
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: hsla(0,0%,99%,0.92);
  display: flex;
  flex-direction: row;
  height: 8vh;
  left: auto;
  position: fixed;
  right: auto;
  top: 0px;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  padding: 0 40px 0 20px;
}

.headerLeft {
  justify-content: flex-start;
}

.headerRight {
  justify-content: flex-end;
}

.link {
  font-family: 'IBM Plex Mono', sans-serif;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
