.article {
    margin-bottom: 50px;
    border-bottom: 1px solid lightgrey;
}

.title {
    text-decoration: none;
    color: rgb(33, 150, 243);
}

.intro {
    font-style: italic;
    margin: 0px 5% 5vh;
}

.date {
    text-align: left;
    color: hsla(0,0%,15%,0.7);
    margin: 0px 5% 2vh;
}